/*--Navigation Bar--*/
@import'../../utilities/variables';




@media only screen and (max-width: $breakpoint-phone) {


  .nav-wrapper {
    position: fixed;
    top: 0px;
    z-index: 20;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.7s;
  
  
  
    .navBackground{
      position: absolute;
      top:0;
      left:0;
      height: 100%;
      width: 100%;
      background: black;
  
    }
  
  
  nav {  
    width: 100%;
    height: 100%;
  
    align-items: center;
    display: flex;
    justify-content: flex-start;
   
    z-index: 20;
  
    .Logo {
      margin-left: 20px;
      height: 35px;
      width: 200px;
    }
    .hamburger {
      z-index: 21;
  
      button {
        outline: none;
        border: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        cursor: pointer;
        position: absolute;
        top: 20%;
        right: 5%;
        width: 50px;
        height: 50px;
        background: transparent;
      }
    }
  }
  }
  .menuBackground {
    position: fixed;
    top: 70px;
    left: 0;
    bottom: 0;
  
  
    align-items: center;
    justify-content: center;
  
    // margin: auto;
  
    width: 100%;
    background: black;
    z-index: 5;
   
  
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
  
      flex-direction: column;
  
      margin: 0;
      padding: 0;
      position: absolute;
      top: 70px;
      
      text-decoration: none;
  
      li {
        width: 100%;

        @include flexCenter();
        flex-direction: column;

        font-size: 12vw;
        font-weight: 500;
        color: white;
        text-decoration: none;

        list-style: none;
        margin-bottom: 1rem;
      }
    }
  
    a {
      text-decoration: none;
    }
  }
  


}




@media only screen and (max-width: $breakpoint-tablet-portrait) and  (min-width: $breakpoint-phone + 1) {


  .nav-wrapper {
    position: fixed;
    top: 0px;
    z-index: 20;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.7s;
  
  
  
    .navBackground{
      position: absolute;
      top:0;
      left:0;
      height: 100%;
      width: 100%;
      background: black;
  
    }
  
  
  nav {
    // position: fixed;
    // top: 0;
    // left: 0;
  
    width: 100%;
    height: 100%;
  
    align-items: center;
    display: flex;
    justify-content: flex-start;
   
    z-index: 20;
  
    .Logo {
      margin-left: 20px;
      height: 35px;
      width: 200px;
    }
    .hamburger {
      z-index: 21;
  
      button {
        outline: none;
        border: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        cursor: pointer;
        position: absolute;
        top: 20%;
        right: 5%;
        width: 50px;
        height: 50px;
        background: transparent;
      }
    }
  }
  }
  .menuBackground {
    position: fixed;
    top: 70px;
    left: 0;
    bottom: 0;
  
  
    align-items: center;
    justify-content: center;
  
    // margin: auto;
  
    width: 100%;
    background: black;
    z-index: 5;
   
  
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
  
      flex-direction: column;
  
      margin: 0;
      padding: 0;
      position: absolute;
      top: 70px;
      
      text-decoration: none;
  
      li {
        width: 100%;

        @include flexCenter();
        flex-direction: column;

        font-size: 8vw;
        font-weight: 500;
        color: white;
        text-decoration: none;

        list-style: none;
        margin-bottom: 1rem;
      }
    }
  
    a {
      text-decoration: none;
    }
  }
  


}


@media only screen and (min-width: $breakpoint-tablet-portrait +1) {

  
.nav-wrapper {
  position: fixed;
  top: 0px;
  z-index: 20;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.7s;



  .navBackground{
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    background: black;

  }


nav {
  // position: fixed;
  // top: 0;
  // left: 0;

  width: 100%;
  height: 100%;

  align-items: center;
  display: flex;
  justify-content: flex-start;
 
  z-index: 20;

  .Logo {
    margin-left: 20px;
    height: 35px;
    width: 200px;
  }
  .hamburger {
    z-index: 21;

    button {
      outline: none;
      border: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      cursor: pointer;
      position: absolute;
      top: 20%;
      right: 5%;
      width: 50px;
      height: 50px;
      background: transparent;
    }
  }
}
}
.menuBackground {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;


  align-items: center;
  justify-content: center;

  // margin: auto;

  width: 100%;
  background: black;
  z-index: 20;
 

  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 0;
    padding: 0;
    position: absolute;
    top: 70px;
    
    text-decoration: none;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 4rem;
      color: white;
      list-style: none;
      margin-bottom: 1rem;
      text-decoration: none;
      width: 100%;
    }
  }

  a {
    text-decoration: none;
  }
}

}


