@import '../../utilities/variables';


.filterContainer{

    height:100%;
    @include flexCenter();

    flex-direction: column;
    background-color: $bgColorWhite;

    color: $textColor;
    font-size: 1rem;

    @media only screen and (max-width: $breakpoint-tablet-portrait) {
        width:100%
    }


    @media only screen and (max-width: $breakpoint-tablet-landscape) and  (min-width: $breakpoint-tablet-portrait + 1) {
        width: 30%;

    }


    @media only screen and (min-width: $breakpoint-tablet-landscape + 1) {
        width: 20%;

    }


    .filterHeader{

        @include flexCenter();
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        padding: 1rem;

        color: $textColor;
        background-color: $bgColorWhiteDkr;


        h2{
            font-size: 1.5rem;
        }

        button{

            border: transparent;
            outline: none;
            background-color: $bgColorWhiteDkr;


            svg{
                filter: invert(1);

            }

        }



    }

    .formContainer{
        width: 100%;
        
    .filterForm{
        @include flexStart();

        padding:1rem;


        flex-direction: column;

        width: 100%;
        height: 100%;
        background-color: $bgColorWhiteDkr;



        .formGroup{
            @include flexStart();

            flex-direction: column;
            width: 100%;

            padding:0.5rem;
            margin:0.5rem;

            .checkboxWrapper{
                @include flexStart();             

                flex-direction: column;

                .checkboxContainer{
                 
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                }

            }
            

            label{
                margin:0.2rem;
                font-size: 0.8rem;
            }

            .formControl{
                padding: 0.2rem;
                font-size: 0.8rem;

                border:0;
                background-color: $bgColorWhite;

                min-width: 150px;
                



                border-radius: 3px;
            }

        }

    }

    
}

}