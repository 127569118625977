@import '../../utilities/variables';



@media only screen and (max-width: $breakpoint-tablet-portrait) {

    
.submissionFormWrapper{

    // position: absolute;
    // top:50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    @include flexCenter();

    width: 100%;
    height: 100%;
    margin: auto;

    padding-bottom: 2rem;



    .contact-submission-form{
        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;
        
        width: 75%;
        height: 100%;

        .contactFormGroup{
            width: 100%;

            padding: 0.25rem;


            @include flexCenter();
            flex-direction: column;

                label{
                    margin:0;
                    height: 1.5rem;
                    text-align: left;
                    width: 100%;
                    letter-spacing: .025em;
                    font-size: 1.125em;
                    line-height: 1.25;
                    position: relative;
                    z-index: 100;
                }

                input[type="text"], input[type="email"]{
                    
                    padding: 0.25rem;

                    height: 2rem;
                    line-height: normal;
                    width: 100%;

                    font-family:inherit;
                    

                }
                input[type="submit"]{
    
                    margin-top: 1.5rem;
                    height: 1.5rem;
                    width: 10rem;
                    height: 50px;
                    background-color: black;
                    color: white;

                    cursor: pointer;

                }




        }

        .contactFormGroup-message{
            width:100%;
            height: 20%;

            @include flexCenter();
            flex-direction: column;

            margin-top: 0.5rem;

            label{
                margin:0;

                height: 1.5rem;
                text-align: left;
                width:100%;
                letter-spacing: .025em;
                font-size: 1.125em;
                line-height: 1.25;
                position: relative;
                z-index: 100;
            }

            textarea{

                width: 100%;
                height: 80px;
                font-family: inherit;

            }

        }
    }
}
.thankYouWrapper{

    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2{
        font-size: 2rem;;

    }

    p{
        font-size: 0.25rem;;

    }


}

}



@media only screen and (min-width: $breakpoint-tablet-portrait + 1) {


    .submissionFormWrapper{

        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
        width: 100%;
        height: 100%;
        margin: auto;
    
        @include flexCenter();
    
    
        .contact-submission-form{
            display: flex;
            align-items: center;
            justify-content: center;
    
            flex-direction: column;
            
            width: 75%;
            height: 100%;
    
            .contactFormGroup{
                width: 100%;
    
                padding: 0.25rem;
    
    
                @include flexCenter();
                flex-direction: row;
    
                    label{
                        margin:0;
                        height: 1.5rem;
                        text-align: left;
                        width: 30%;
                        letter-spacing: .025em;
                        font-size: 1.125em;
                        line-height: 1.25;
                        position: relative;
                        z-index: 100;
                    }
    
                    input[type="text"], input[type="email"]{
                        
                        padding: 0.25rem;
    
                        height: 2rem;
                        line-height: normal;
                        width: 70%;
    
                        font-family:inherit;
                        
    
                    }
                    input[type="submit"]{
        
                        margin-top: 1.5rem;
                        height: 1.5rem;
                        width: 10rem;
                        height: 50px;
                        background-color: black;
                        color: white;
    
                        cursor: pointer;
    
                    }
    
    
    
    
            }
    
            .contactFormGroup-message{
                width:100%;
                height: 20%;
    
                @include flexCenter();
                flex-direction: row;
    
                margin-top: 0.5rem;
    
                label{
                    margin:0;
    
                    height: 1.5rem;
                    text-align: left;
                    width: 30%;
                    letter-spacing: .025em;
                    font-size: 1.125em;
                    line-height: 1.25;
                    position: relative;
                    z-index: 100;
                }
    
                textarea{
    
                    width: 70%;
                    height: 100%;
                    font-family: inherit;
    
                }
    
            }
        }
    }
    .thankYouWrapper{
    
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
        h2{
            font-size: 2rem;;
    
        }
    
        p{
            font-size: 0.25rem;;
    
        }
    
    
    }
    


}




  

    


    
