
$textColor: #363636;
$bgColorWhite: #ececec;
$bgColorWhiteDkr: #dedede;
$bgColorGrey: #5c5c5c;
$bgTransparentBlack: rgba(0, 0, 0,0.8);

$titleSize: 1rem;

//Breakpoints

$breakpoint-phone: 480px;
$breakpoint-tablet-portrait: 768px;
$breakpoint-tablet-landscape: 1024px;
$breakpoint-desktop: 1366px;


@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexStart {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}



@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,400&display=swap");


body{
  p{
      font-family: Poppins, Arial, Helvetica, sans-serif;
      font-size: 3rem;
  }
  
}

*{
  box-sizing: border-box;
}


section{

    width: 100%;
    min-height: 100vh;
    background-color: $textColor;


}

