@import '../../utilities/variables';


.loadingCover{
 
    position: absolute;
    top:0;
    left:0;

    width: 100%;
    height: 100vh;
    
    background-color: black;
    z-index: 100;

}


@media only screen and  (max-width: $breakpoint-phone) {
    
    .projectWrapper{
        height:auto;
        width: 100%;
        background-color: $bgColorWhite;
    
    
        .page-wrapper{
    
            width: 100%;
            height:fit-content;
    
            @include flexCenter();
            flex-direction: column;
        
        //Project page//
        
            .projectImages{
                @include flexCenter();
                flex-direction: column;
        
                width:100%;
                height:fit-content;
        
                background-color: $bgColorWhite;
                padding: 1rem 2rem 1rem 2rem;
                
                .image{
                    margin-top: 50px;
                    width: 100%;
        
                    height:auto;
        
                    img{
                        width: 100%;
        
                        object-fit: cover;
                        }
        
                    
                    }
        
                }
        }
    
        .project-hero{
    
            @include flexCenter();
            flex-direction: column;
        
            // position: sticky;
            // position: -webkit-sticky;
            top: 70px;
        
        
            width:100%;
            height: auto;
            // height: calc(100vh - 70px);
        
            // visibility: hidden;
        
            background-color: $bgColorWhite;
        
            .title-container{
        
                @include flexCenter();
                flex-direction: column;
        
                height: 100%;
        
        
            // display: flex;
            // flex-direction: column;
        
            // background-color: yellow;
            // padding: 2em;
        
                .header{
                    height: auto;
                    width:100%;
                    max-width: 100%;
    
                    margin-top: 70px;
        
                    box-sizing: border-box;
        
                    @include flexCenter();
                    
                    h1{
                        color: $bgColorGrey;
                        font-size: 12vw;
                    }
                      
        
                }
        
                .description{

                    width: 100%;
                    height: auto;
    
                    @include flexCenter();
                    flex-direction: column;
    
                    padding: 0 2rem 0 2rem;
                    
    
                    
                    a{
                       text-decoration: none;
                    }
        
                    .projectBackButton{
        
                        @include flexCenter();
        
                        width: 150px;
                        height: 50px;
        
                        
        
                        background-color: $bgColorGrey;
                        p{
                            color: $bgColorWhite;
            
                        }
            
                        border-radius: 10px;
            
                    }
        
        
                }
        
        
                p{
                    color: $bgColorGrey;
                    font-size:1rem;
        
                }
                
            }
        
        }
        
    
    
    
    
    }
    }

@media only screen and  (min-width: $breakpoint-phone + 1) and (max-width: $breakpoint-tablet-portrait) {
    
.projectWrapper{
    height:auto;
    width: 100%;
    background-color: $bgColorWhite;


    .page-wrapper{

        width: 100%;
        height:fit-content;

        @include flexCenter();
        flex-direction: column;
    
    //Project page//
    
        .projectImages{
            @include flexCenter();
            flex-direction: column;
    
            width:100%;
            height:fit-content;
    
            background-color: $bgColorWhite;
            padding: 1rem 2rem 1rem 2rem;
            
            .image{
                margin-top: 50px;
                width: 100%;
    
                height:auto;
    
                img{
                    width: 100%;
    
                    object-fit: cover;
                    }
    
                
                }
    
            }
    }

    .project-hero{

        @include flexCenter();
        flex-direction: column;
    
        // position: sticky;
        // position: -webkit-sticky;
        top: 70px;
    
    
        width:100%;
        height: auto;
        // height: calc(100vh - 70px);
    
        // visibility: hidden;
    
        background-color: $bgColorWhite;
    
        .title-container{
    
            @include flexCenter();
            flex-direction: column;
    
            height: 100%;
    
    
        // display: flex;
        // flex-direction: column;
    
        // background-color: yellow;
        // padding: 2em;
    
            .header{
                height: auto;
                width:100%;
                max-width: 100%;

                margin-top: 70px;
    
                box-sizing: border-box;
    
    
                @include flexCenter();
                
                // writing-mode: vertical-rl;
                // text-orientation: mixed;
                // transform: rotate(180deg);
                // padding: 2rem 0 2rem 0;

    
            }
    
            .description{
                width: 100%;
                height: auto;

                @include flexCenter();
                flex-direction: column;

                padding: 0 2rem 0 2rem;
                

                
                a{
                   text-decoration: none;
                }
    
                .projectBackButton{
    
                    @include flexCenter();
    
                    width: 150px;
                    height: 50px;
    
                    
    
                    background-color: $bgColorGrey;
                    p{
                        color: $bgColorWhite;
        
                    }
        
                    border-radius: 10px;
        
                }
    
    
            }
    
           
            
            h1{
                color: $bgColorGrey;
                font-size:4rem;
            }
    
            p{
                color: $bgColorGrey;
                font-size:1rem;
    
            }
            
        }
    
    }
    




}
}

@media only screen and (min-width: $breakpoint-tablet-portrait + 1 ) {
    
.projectWrapper{
    height:auto;
    width: 100%;
    background-color: $bgColorWhite;

.page-wrapper{

    width: 100%;
    height:fit-content;

    display: flex;
    justify-content: center;

//Project page//

    .projectImages{
        @include flexCenter();
        flex-direction: column;

        width:60%;
        height:fit-content;

        background-color: $bgColorWhite;
        padding: 20px 10px 10px 20px;
        
        .image{
            margin-top: 50px;
            width: 100%;

            height:auto;

            img{
                width: 100%;

                object-fit: cover;
                }

            
            }

        }
}


    .project-hero{
        width:40%;
        height: calc(100vh - 70px);

        @include flexCenter();
    
        position: sticky;
        position: -webkit-sticky;

        top: 70px;
        margin-top: 70px;

        background-color: $bgColorWhite;

    
        .title-container{
            height: 100%;
            width: 100%;
            // @include flexStart();
            @include flexCenter();
            flex-direction: column;

    
            .header{
                height: 100%;
                width:auto;
                // max-width: 50%;

                @include flexCenter();
    
                
                box-sizing: border-box;
                   
                // writing-mode: vertical-rl;
                // text-orientation: mixed;
                // transform: rotate(180deg);
                padding: 1rem 0 1rem 0;
    
            }
    
            .description{
                height: 100%;
                width: 90%;
                padding: 1rem 0 1rem 0;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                overflow: scroll;
                overflow-x: hidden;

                &::-webkit-scrollbar{
                    width: 5px;
                }
            
                

                // // -webkit-scrollbar-track {
                //     box-shadow: inset 0 0 5px grey; 
                //     border-radius: 10px;
                // }
    
                a{
                    text-decoration: none;
                }
    
                .projectBackButton{
    
                    @include flexCenter();
    
                    width: 150px;
                    height: 50px;
    
                    
    
                    background-color: $bgColorGrey;
                    p{
                        color: $bgColorWhite;
        
                    }
        
                    border-radius: 10px;
        
                }
    
    
            }
    
           
            
            h1{
                color: $bgColorGrey;
                font-size:4rem;
            }
    
            p{
                color: $bgColorGrey;
                font-size:1rem;
    
            }
            
        }
    
    }
    




}
}



    //CSS to change footer to sticky
    
    footer{
        position: sticky;
        top: 500px;
    }