@import'../../../utilities/variables';


@media only screen and (max-width: $breakpoint-phone) {


  .slider-section{

    width: 100%;
    min-height: 70vh;

    height:90vh;
    position: relative;

    .slide-wrapper{
        width: 100%;
        height:100%;
        margin: 0;
        padding: 0;


      .slide{
        box-sizing: border-box;
        width: 100%;
        height:100%;
        margin: 0;
        padding:10px;     
        
        display: flex;
        align-items:center;
        justify-content:flex-end;
        flex-direction: column;

        background-size: cover;
        

        .slide-text-container{
          box-sizing: border-box;
          padding:30px;
          margin:0;
          background-color: rgba(0, 0, 0,0.5);

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;



          .slide-title{
            display: flex;
            align-items: center;
            
            
            height:25%;
            padding: 0 0 0 0;
            margin: 0;
            border: 0;
            font-size: 2rem;        
            
          
          }
  
          .slide-subtitle{
            display: flex;
            align-items: center;
            height:25%;
            padding:0;
            margin: 0;
            font-size:1rem;
          }
          .slide-link{
            display: flex;
            align-items: center;
            height:10%;
            padding:0;
            margin: 0;
            font-size:0.7rem;


          }
          a {
            text-decoration: none;
            color: lightgray;
          }         


        }
      

      }

      .leftButtonContainer{

        height: 50%;
        width: 25%;

        position: absolute;
        top:50%;
        left:0;
        transform: translate(0, -50%);

        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 2rem;

        font-size: 3rem;
        z-index: 10;


      }

      .rightButtonContainer{

        height: 50%;
        width: 25%;

        position: absolute;
        top:50%;
        right:0;
        transform: translate(0, -50%);

        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 2rem;

        font-size: 3rem;
        z-index: 10;
      }

    }
  
    .dots-container{

      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      bottom: 0;
      left: 0;

    
      width:100%;
      height:20px;

      z-index: 5;
    }

}





}



@media only screen and (max-width: $breakpoint-tablet-portrait) and (min-width: $breakpoint-phone +1 )  {



    .slider-section{

      width: 100%;
      min-height: 70vh;

      height:90vh;
      position: relative;

      .slide-wrapper{
          width: 100%;
          height:100%;
          margin: 0;
          padding: 0;


        .slide{
          box-sizing: border-box;
          width: 100%;
          height:100%;
          margin: 0;
          padding:10px;     
          
          display: flex;
          align-items:center;
          justify-content:flex-end;
          flex-direction: column;

          background-size: cover;
          

          .slide-text-container{
            box-sizing: border-box;
            padding:30px;
            margin:0;
            background-color: rgba(0, 0, 0,0.5);

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;



            .slide-title{
              display: flex;
              align-items: center;
              
              
              height:25%;
              padding: 0 0 0 0;
              margin: 0;
              border: 0;
              font-size: 2rem;        
              
            
            }
    
            .slide-subtitle{
              display: flex;
              align-items: center;
              height:25%;
              padding:0;
              margin: 0;
              font-size:1rem;
            }
            .slide-link{
              display: flex;
              align-items: center;
              height:10%;
              padding:0;
              margin: 0;
              font-size:0.7rem;


            }
            a {
              text-decoration: none;
              color: lightgray;
            }         


          }
        

        }

        .leftButtonContainer{

          height: 50%;
          width: 25%;

          position: absolute;
          top:50%;
          left:0;
          transform: translate(0, -50%);

          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 2rem;

          font-size: 3rem;
          z-index: 10;


        }

        .rightButtonContainer{

          height: 50%;
          width: 25%;

          position: absolute;
          top:50%;
          right:0;
          transform: translate(0, -50%);

          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 2rem;

          font-size: 3rem;
          z-index: 10;
        }

      }
    
      .dots-container{

        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        bottom: 0;
        left: 0;

      
        width:100%;
        height:20px;

        z-index: 5;
      }

  }



}


@media only screen and (max-width: $breakpoint-tablet-landscape) and (min-width: $breakpoint-tablet-portrait +1 )  {


  .slider-section{

    width: 100%;
    min-height: 70vh;

    height:80vh;
    position: relative;

    .slide-wrapper{
        width: 100%;
        height:100%;
        margin: 0;
        padding: 0;


      .slide{
        box-sizing: border-box;
        width: 100%;
        height:100%;
        margin: 0;
        padding:10px;     
        
        display: flex;
        align-items:center;
        justify-content:flex-end;
        flex-direction: column;

        background-size: cover;
        

        .slide-text-container{
          box-sizing: border-box;
          padding:30px;
          margin:0;
          background-color: rgba(0, 0, 0,0.5);

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;



          .slide-title{
            display: flex;
            align-items: center;
            
            
            height:25%;
            padding: 0 0 0 0;
            margin: 0;
            border: 0;
            font-size: 2rem;        
            
          
          }
  
          .slide-subtitle{
            display: flex;
            align-items: center;
            height:25%;
            padding:0;
            margin: 0;
            font-size:1rem;
          }
          .slide-link{
            display: flex;
            align-items: center;
            height:10%;
            padding:0;
            margin: 0;
            font-size:0.7rem;


          }
          a {
            text-decoration: none;
            color: lightgray;
          }         


        }
      

      }

      .leftButtonContainer{

        height: 50%;
        width: 25%;

        position: absolute;
        top:50%;
        left:0;
        transform: translate(0, -50%);

        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 2rem;

        font-size: 3rem;
        z-index: 10;


      }

      .rightButtonContainer{

        height: 50%;
        width: 25%;

        position: absolute;
        top:50%;
        right:0;
        transform: translate(0, -50%);

        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 2rem;

        font-size: 3rem;
        z-index: 10;
      }

    }
  
    .dots-container{

      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      bottom: 0;
      left: 0;

    
      width:100%;
      height:20px;

      z-index: 5;
    }

}




}



@media only screen and (min-width: $breakpoint-tablet-portrait +1) {


  .slider-section{

      width: 100%;
      height:90vh;
      position: relative;

      .slide-wrapper{
          width: 100%;
          height:100%;
          margin: 0;
          padding: 0;


        .slide{
          box-sizing: border-box;
          width: 100%;
          height:100%;
          margin: 0;
          padding:10px;
          

          display: flex;
          flex-direction: column;
          align-items:flex-start;
          justify-content:flex-end;
        
          background-size: cover;
          

          .slide-text-container{
            box-sizing: border-box;
            padding:30px;
            margin:0;
            background-color: rgba(0, 0, 0,0.5);

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;



            .slide-title{
              display: flex;
              align-items: center;
              
              
              height:25%;
              padding: 0 0 0 0;
              margin: 0;
              border: 0;
              font-size: 2rem;        
              
            
            }
    
            .slide-subtitle{
              display: flex;
              align-items: center;
              height:25%;
              padding:0;
              margin: 0;
              font-size:1rem;
            }
            .slide-link{
              display: flex;
              align-items: center;
              height:10%;
              padding:0;
              margin: 0;
              font-size:0.7rem;
  

            }
            a {
              text-decoration: none;
              color: lightgray;
            }         


          }
        

        }

        .leftButtonContainer{

          height: 50%;
          width: 25%;

          position: absolute;
          top:50%;
          left:0;
          transform: translate(0, -50%);

          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 2rem;

          font-size: 3rem;
          z-index: 10;


        }

        .rightButtonContainer{

          height: 50%;
          width: 25%;

          position: absolute;
          top:50%;
          right:0;
          transform: translate(0, -50%);

          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 2rem;

          font-size: 3rem;
          z-index: 10;



        }




      }
    
      .dots-container{

        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        bottom: 0;
        left: 0;

      
        width:100%;
        height:20px;

        z-index: 5;
      }

  }


}