@import '../../utilities/variables.scss';

//Mobile


@media only screen and (max-width: $breakpoint-phone) {

    
    footer{

        width: 100%;
        min-height: 60vh;
        height: 100vh;


        

        .footer-container{

            display: flex;
            flex-wrap: wrap;

            
            padding-top:1rem;
            padding-bottom: 1rem;

            width: 100%;
            height: 100%;
        
            background-color: #333;
        
            .footer-grid{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
        
                width: 100%;
                height: 100%;
        
                .footer-logo-section{

                    display: flex;
                    align-items: center;
                    justify-content: center;
        

                    min-width: 20%;
                    height: 30%;
                
        
                }
                .footer-nav-links{

                    display: none;

                    // @include flexCenter();

                    // min-width: 350px;
                    // width: 15%;
                    // height: 50%;
                    // text-align: center;
                    // list-style: none;

                    // nav{
                    // @include flexCenter();

                    //     ul{
                    //         @include flexCenter();
                    //         flex-direction: column;


                    //     }
                    // }

                    

                    a{
                        text-decoration: none;
                        color:white;
                    }
                    
        
                }
                .footer-accreditations{

                    display: flex;
                    justify-content: space-around;
                    align-items: center;
        
                    // width: 30%;
                    max-width: 250px;
                    height: 30%;
            
                    flex-wrap: wrap;

                    .acc-logo{

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: auto;
                        width: 40%;

                        padding: 0.25rem;
                        margin: 0.25rem;
                        img{
                            height: auto;
                            width: auto;
                            max-height: 55px;
                        }

                    }
        
                    
                }
                .footer-contact{
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    min-width: 350px;
                    
                    width: 15%;
                    height: 30%;
                    
                    ul{
                        align-items: center;
                        li{
                            width:100%;
                            height: 5%;
                            a{
                                text-decoration: none;
                                color: inherit;
                            }
                        }
                    }
        
                }
        
            }
        }

    }


}


//Tablet Portrait

@media only screen and (max-width: $breakpoint-tablet-portrait) and (min-width:$breakpoint-phone +1 ) {

    
    footer{

        width: 100%;
        min-height: 20vh;
        height: 50vh;


        

        .footer-container{

            display: flex;
            flex-wrap: wrap;

            
            padding-top:1rem;
            padding-bottom: 1rem;

            width: 100%;
            height: 100%;
        
            background-color: #333;
        
            .footer-grid{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
        
                width: 100%;
                height: 100%;
        
                .footer-logo-section{

                    display: flex;
                    align-items: center;
                    justify-content: center;
        

                    min-width: 20%;
                    height: 30%;
                
        
                }
                .footer-nav-links{

                    display: none;

                    // @include flexCenter();

                    // min-width: 350px;
                    // width: 15%;
                    // height: 50%;
                    // text-align: center;
                    // list-style: none;

                    // nav{
                    // @include flexCenter();

                    //     ul{
                    //         @include flexCenter();
                    //         flex-direction: column;


                    //     }
                    // }

                    

                    a{
                        text-decoration: none;
                        color:white;
                    }
                    
        
                }
                .footer-accreditations{

                    display: flex;
                    justify-content: space-around;
                    align-items: center;
        
                    // width: 30%;
                    max-width: 250px;
                    height: 30%;
            
                    flex-wrap: wrap;

                    .acc-logo{

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: auto;
                        width: 40%;

                        padding: 0.25rem;
                        margin: 0.25rem;
                        img{
                            height: auto;
                            width: auto;
                            max-height: 55px;
                        }

                    }
        
                    
                }
                .footer-contact{
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    min-width: 350px;
                    
                    width: 15%;
                    height: 30%;
                    
                    ul{
                        align-items: center;
                        li{
                            width:100%;
                            height: 5%;
                            a{
                                text-decoration: none;
                                color: inherit;
                            }
                        }
                    }
        
                }
        
            }
        }

    }


}


//Tablet Landscape

@media only screen and (max-width: $breakpoint-tablet-landscape) and (min-width:$breakpoint-tablet-portrait +1 )  {

    
    footer{

        width: 100%;
        min-height: 60vh;
        height: 50vh;
       

        .footer-container{

            display: flex;
            flex-wrap: wrap;

            
            padding-top:1rem;
            padding-bottom: 1rem;

            width: 100%;
            height: 100%;
        
            background-color: #333;
        
            .footer-grid{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
        
                width: 100%;
                height: 100%;
        
                .footer-logo-section{

                    display: flex;
                    align-items: center;
                    justify-content: center;
        

                    min-width: 20%;
                    height: 30%;
                
        
                }
                .footer-nav-links{

                    display: none;

                    // @include flexCenter();

                    // min-width: 350px;
                    // width: 15%;
                    // height: 50%;
                    // text-align: center;
                    // list-style: none;

                    // nav{
                    // @include flexCenter();

                    //     ul{
                    //         @include flexCenter();
                    //         flex-direction: column;


                    //     }
                    // }

                    

                    a{
                        text-decoration: none;
                        color:white;
                    }
                    
        
                }
                .footer-accreditations{

                    display: flex;
                    justify-content: space-around;
                    align-items: center;
        
                    // width: 30%;
                    max-width: 250px;
                    height: 30%;
            
                    flex-wrap: wrap;

                    .acc-logo{

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: auto;
                        width: 40%;

                        padding: 0.25rem;
                        margin: 0.25rem;
                        img{
                            height: auto;
                            width: auto;
                            max-height: 55px;
                        }

                    }
        
                    
                }
                .footer-contact{
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    min-width: 350px;
                    
                    width: 15%;
                    height: 30%;
                    
                    ul{
                        align-items: center;
                        li{
                            width:100%;
                            height: 5%;
                            a{
                                text-decoration: none;
                                color: inherit;
                            }
                        }
                    }
        
                }
        
            }
        }

    }


}


//Desktop
@media only screen and (min-width: $breakpoint-tablet-landscape +1) and (min-width:$breakpoint-tablet-portrait +1 )  {

    footer{

        width: 100%;
        min-height: 60vh;
        height: 60vh;


        

        .footer-container{

            display: flex;
            flex-wrap: wrap;

            
            padding-top:1rem;
            padding-bottom: 1rem;

            width: 100%;
            height: 100%;
        
            background-color: #333;
        
            .footer-grid{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
        
                width: 100%;
                height: 100%;
        
                .footer-logo-section{

                    display: flex;
                    align-items: center;
                    justify-content: center;
        

                    min-width: 20%;
                    height: 50%;
                
        
                }
                .footer-nav-links{

                    @include flexCenter();

                    min-width: 350px;
                    width: 15%;
                    height: 50%;
                    text-align: center;
                    list-style: none;

                    nav{
                    @include flexCenter();

                        ul{
                            @include flexCenter();
                            flex-direction: column;


                        }
                    }

                    

                    a{
                        text-decoration: none;
                        color:white;
                    }
                    
        
                }
                .footer-accreditations{

                    display: flex;
                    justify-content: space-around;
                    align-items: center;
        
                    // width: 30%;
                    max-width: 250px;
            
                    flex-wrap: wrap;

                    .acc-logo{

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: auto;
                        width: 40%;

                        padding: 0.25rem;
                        margin: 0.25rem;
                        img{
                            height: auto;
                            width: auto;
                            max-height: 55px;
                        }

                    }
        
                    
                }
                .footer-contact{
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    min-width: 350px;
                    
                    width: 15%;
                    height: 50%;
                    
                    ul{
                        align-items: center;
                        li{
                            width:100%;
                            height: 5%;
                            a{
                                text-decoration: none;
                                color: inherit;
                            }
                        }
                    }
        
                }
        
            }
        }

    }
}