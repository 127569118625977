@import '../../utilities/variables';



@media only screen and (max-width: $breakpoint-tablet-portrait) {
        section{

    .contact-wrapper{

        display: flex;
        justify-content: center;
        align-items: center;

        flex-direction: column;

        width: 100%;
        height: 100%;

        padding-top: 70px;

        background-color: $bgColorWhite;


        .contact-details-wrapper{
            @include flexCenter();
            flex-direction: column;

            height: 100%;
            width: 100%;

            .contact-header{
                @include flexCenter();

                height:20%;
                width:100%;

                h1{
                    font-size: 3rem;
                    font-weight: 500;
                    color: $textColor;
                }
            }

            .contact-pre-text{
                width: 90%;

                p{
                    width: 100%;
                    font-size: 1rem;
                    color: $textColor;
                }

            }



            .contact-form{

                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                position: relative;           
            
                width: 100%;
                height:50%;
            
            
            
                color: $textColor;
            
                h1{
                    font-size: 3rem;
                }
            
                p{
                    font-size: 1.5rem;
                    color: $textColor;
                }
            
                ul{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
            
                li{
                    
                    height: 2rem;
                }
            
            }
            .contact-address{
                width: 75%;
                height: 40%;
                color: $textColor;

                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                ul{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

            }

        }

        .contact-map{

            width: 100%;
            height: 600px;

            .googleMap{

                width:100%;
                height: 100%;
            }

        }
    }
}
}


@media only screen and (min-width: $breakpoint-tablet-portrait + 1) {
    section{
        height: 100vh;
        .contact-wrapper{

            display: flex;
            justify-content: center;
            align-items: center;

            flex-direction: row;

            width: 100%;
            height: 100%;

            padding-top: 70px;

            background-color: $bgColorWhite;


            .contact-details-wrapper{
                @include flexCenter();
                flex-direction: column;

                justify-content: flex-start;
                height: 100%;
        
            @media only screen and (max-width: $breakpoint-tablet-portrait) {
                width: 100%;
            }


            @media only screen and (min-width: $breakpoint-tablet-portrait + 1) {
                width: 50%;
            }


                .contact-header{
                    display: flex;
                    align-items: flex-start;
                    height:20%;
                    width:100%;

                    padding-left: 2rem;


                    h1{
                        font-size: 3rem;
                        font-weight: 500;
                        color: $textColor;
                    }
                }

                .contact-pre-text{
                    padding: 1.5rem;

                    p{
                        font-size: 1rem;
                        color: $textColor;
                    }

                }



                .contact-form{

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    position: relative;
                
                
                    width: 100%;
                    height:50%;
                
                
                
                    color: $textColor;
                
                    h1{
                        font-size: 3rem;
                    }
                
                    p{
                        font-size: 1.5rem;
                        color: $textColor;
                    }
                
                    ul{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                
                    li{
                        
                        height: 2rem;
                    }
                
                }
                .contact-address{
                    width: 75%;
                    height: 40%;
                    color: $textColor;

                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    ul{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                    }

                }

            }

            .contact-map{

                width: 50%;
                height: 100%;

                .googleMap{

                    width:50%;
                    height: 100%;
                }

            }
        }
}
}



