@import '../../breakpoints.scss';
@import '../../utilities/variables';

    
.pageHeroContainer{
    width: 100%;
    height:fit-content;


    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    .pageHeroImage{

        width: 100%;
        height: 100vh;
        overflow: hidden;
             
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }



    
@media only screen and (max-width: $breakpoint-tablet-portrait) {

          

    .pageHeroText{

        display: flex;
        justify-content:center;
        align-items: center;

        padding: 3rem;

        width: 100%;
        height: 40vh;
        background-color: black;

        h1{

            color: $bgColorWhite;
            font-size: 12vw;

        }

    }

    

}

@media only screen and (min-width: $breakpoint-tablet-portrait + 1) {


    .pageHeroText{

        display: flex;
        justify-content:flex-start;
        align-items: center;

        padding: 3rem;

        width: 100%;
        height: 40vh;
        background-color: black;

        h1{

            color: $bgColorWhite;
            font-size: 5rem;

        }

    }
}


  




}
