@import '../../utilities/variables';






.projectCard{

    @include flexCenter();
    min-height: 400px;
    min-width: 200px;
    
    height: 33vh;

    margin: 1em;
    padding: 0;

    @media only screen and (max-width: $breakpoint-phone) {
        width: 100%;
    }
        
    @media only screen and (max-width: $breakpoint-tablet-landscape)  and (min-width: $breakpoint-phone +1) {
        width: 45%;
    }

    
    @media only screen and (min-width: $breakpoint-tablet-landscape + 1) {
        width: 30%;
    }

    .projectContainer{


        @include flexCenter();
        flex-wrap: wrap;

        height:100%;
        width: 100%;
        background-color: white;
        position: relative;

            

            .projectImage{

                width: 100%;
                height: 100%;

            
                img{
                    width: 100%;
                    height:100%;
                    
                    object-fit: cover;

                

                }

            }

            .projectInfo{
                width: 100%;
                min-height:20%;

                @include flexCenter();
                flex-direction: column;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                padding:1rem;
                background-color: $bgColorWhiteDkr;


                ul{
                    @include flexCenter();
                    flex-direction: column;
                    width: 100%;

                    li{
                        width: 100%;
                        
                            h1{
                                font-size: 1rem;
                                font-weight: 600;
                                color: $textColor;
                                text-transform: uppercase;
                                text-decoration: none;
                            }

                            .projectType{
                                font-size: 0.8rem;
                                font-weight: 200;
                                color:$textColor;
                            }

                            .seeMoreBtn{
                                width: 75%;
                                height:50px;
                                margin: 0.6rem;

                                border-style: solid;
                                border-color: $bgColorGrey;
                                border-width: 2px;

                                cursor: pointer;
                                transition: 0.3s;

                                &:hover{
                                    background-color: $bgColorGrey;
                                    color: $bgColorWhite;
                                }

                            }


                    }
                }

            }
    }

    }


