@import'../../utilities/variables';





    .servicesPageWrapper{

        @include flexCenter();
    
        flex-direction: column;
    
        height:auto;
        width: 100%;
    
        background-color: $bgColorWhite;
    
    
        .servicesSection{
    
            display: flex;
    
            height: auto;
            width: 100%;
            flex-wrap: wrap;
        
            &:nth-child(even){
                flex-direction: row-reverse;
                text-align: right;
                background-color: $bgColorWhite;
                align-items: flex-end;
            }
        
            &:nth-child(odd){
                flex-direction: row;
                text-align: left;
                background-color: $bgColorWhiteDkr;
                align-items: flex-start;
        
            }
    
            
            .serviceHeaderText{
    
                width: 85%;
                height: auto;
    
                @include flexCenter();
                flex-direction: column;
    
                margin: auto;
                padding: 1rem;
    
                p{
                    margin:0;
                    padding:0.25rem;
                    font-size: 1rem;
                    color: $bgColorGrey;
                }
    
    
            }

            
            @media only screen and (max-width: $breakpoint-tablet-portrait) {

                .serviceSectionWrapper{

                    height: auto;
                    min-height: 80vh;
                    width:85%;
                    margin: auto;
                    padding: 1.5rem ;
                
                    background-color: transparent;
                
                    @include flexCenter();
                
                    flex-wrap: wrap;
                
                    flex-direction: inherit;
                    text-align: inherit;
        
                    align-items: inherit;
        
        
                    .serviceSectionText{
        
                        display: flex;
                        flex-direction: column;
        
                        align-items: center;
                        justify-content: center;
                        
                        height: 100%;
                        width: 100%;
                        margin: auto;
                        padding: 1.5rem;
        
                        
                        .serviceSectionTitle{
        
                            h2{
                                color: $bgColorGrey;
                                font-size: 1.5rem;
                                text-align: center;

                            }
        
        
                        }
        
                        .serviceSectionDescription{
                            p{
                                color: $bgColorGrey;
                                font-size: 1rem;
                                text-align: center;
                                }
        
                        }
        
                        .serviceLinkBtnWrap{
                            width: 75%;
                            height:50px;
                            margin: 0.6rem;
        
        
                            .learnMoreBtn{
                                width: 100%;
                                height:100%;
            
                                border-style: solid;
                                border-color: $bgColorGrey;
                                border-width: 2px;
            
                                cursor: pointer;
                                transition: 0.3s;
            
                                &:hover{
                                    background-color: $bgColorGrey;
                                    color: $bgColorWhite;
                                }
                            }
        
                        }
                                           
                    }
        
                        .serviceSectionImage{
                            display: flex;
                            justify-content: flex-start;
        
                            height: 100%;
                            min-height: 220px;
                            width: 100%;
                    
                    
                            img{
                            width:100%;
                            height: 100%;
                            margin: auto;
                            object-fit: cover;
                            }
        
        
                        }
         
        
                }
        
            }
    

            @media only screen and (min-width: $breakpoint-tablet-portrait + 1) {

                .serviceSectionWrapper{
    
                    height: 60vh;
                    width:85%;
                    margin: auto;
                    padding: 3rem ;
                
                    background-color: transparent;
                
                    @include flexCenter();
                
                    flex-wrap: wrap;
                
                    flex-direction: inherit;
                    text-align: inherit;
        
                    align-items: inherit;
        
        
                    .serviceSectionText{
        
                        display: flex;
                        flex-direction: column;
        
                        align-items: inherit;
                        justify-content: center;
                        
                        height: 100%;
                        width: 50%;
                        margin: auto;
                        padding: 3rem;
        
                        
                        .serviceSectionTitle{
        
                            h2{
                                color: $bgColorGrey;
                                font-size: 1.5rem;
                            }
        
        
                        }
        
                        .serviceSectionDescription{
                            p{
                                color: $bgColorGrey;
                                font-size: 1rem;
                                }
        
                        }
        
                        .serviceLinkBtnWrap{
                            width: 75%;
                            height:50px;
                            margin: 0.6rem;
        
        
                            .learnMoreBtn{
                                width: 100%;
                                height:100%;
            
                                border-style: solid;
                                border-color: $bgColorGrey;
                                border-width: 2px;
            
                                cursor: pointer;
                                transition: 0.3s;
            
                                &:hover{
                                    background-color: $bgColorGrey;
                                    color: $bgColorWhite;
                                }
            
                                
            
                            }
        
        
                        }
                      
        
                       
                    }
        
                        .serviceSectionImage{
                            display: flex;
                            justify-content: flex-start;
        
                            height: 100%;
                            width: 50%;
                    
                    
                            img{
                            width:85%;
                            height: 100%;
                            margin: auto;
                            object-fit: cover;
                            }
        
        
                        }
         
        
                }
        
            }




    
        }
    }