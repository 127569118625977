@import'../../utilities/variables';


.aboutSection {
  height: auto;
  width: 100%;
  flex-wrap: wrap;

  &:nth-child(even){
    flex-direction: row-reverse;
    text-align: right;
    background-color: $bgColorWhite;

  }

  &:nth-child(odd){
    flex-direction: row;
    text-align: left;
    background-color: $bgColorWhiteDkr;

  }

  background-color: $bgColorWhite;



  @media only screen and (max-width: $breakpoint-tablet-portrait) {



    .aboutSectionWrapper{

      height: auto;
      min-height: 80vh;
      width:100%;
      margin: auto;
      padding: 1.5rem ;
  
      background-color: transparent;
  
      @include flexCenter();
  
      flex-wrap: wrap;
  
      flex-direction: inherit;
      text-align: inherit;
  
    
      .aboutImage  {

      @include flexCenter();


        height: 50%;
        width: 100%;
  
        margin: auto;
        
  
  
        img{
          width:85%;
          height: 100%;
          object-fit: cover;
        }
  
      }
  
      .aboutText{
        display: flex;
        flex-direction: column;
        
        justify-content: center;
        
        height: 100%;
        width: 100%;
        margin: auto;
        padding: 1.5rem;

        text-align: center;
  
  
        h2{
          color: $bgColorGrey;
          font-size: 1.5rem;

          text-align: inherit;
        }
  
        p{
          color: $bgColorGrey;
          font-size: 1rem;

          text-align: inherit;

        }
      }
    }



  }


  @media only screen and (max-width: $breakpoint-tablet-landscape) and (min-width: $breakpoint-tablet-portrait + 1) {

    .aboutSectionWrapper{

      height: 100vh;
      width:100%;
      margin: auto;
      padding: 1.5rem ;
  
      background-color: transparent;
  
      @include flexCenter();
  
      flex-wrap: wrap;
  
      flex-direction: inherit;
      text-align: inherit;
  
      div{
        @include flexCenter();

        flex-direction: inherit;
        text-align: inherit;

        .fadeInBox{

          @include flexCenter();
          
          flex-direction: inherit;
          text-align: inherit;

          .aboutImage  {
            height: 100%;
            width: 40%;
      
            margin: auto;
            
      
      
            img{
              width:100%;
              height: 100%;
              object-fit: cover;
            }
      
          }
      

          .aboutText{
            display: flex;
            flex-direction: column;
            
            justify-content: center;
            
            height: 100%;
            width: 40%;
            margin: auto;
            padding: 3rem;

            text-align: inherit;
      
      
            h2{
              width: 100%;
              text-align: inherit;
              color: $bgColorGrey;
              font-size: 1.5rem;
            }
      
            p{
            text-align: inherit;

              color: $bgColorGrey;
              font-size: 1rem;
            }
          }





        }


      }
    
    }

  }


  @media only screen  and (min-width: $breakpoint-tablet-landscape + 1) {

    .aboutSectionWrapper{

      height: 100vh;
      width:85%;
      margin: auto;
      padding: 3rem ;
  
      background-color: transparent;
  
      @include flexCenter();
  
      flex-wrap: wrap;
  
      flex-direction: inherit;
      text-align: inherit;
  
      div{
        @include flexCenter();

        flex-direction: inherit;
        text-align: inherit;

        .fadeInBox{

          @include flexCenter();
          
          flex-direction: inherit;
          text-align: inherit;

          .aboutImage  {
            height: 100%;
            width: 35%;
      
            margin: auto;
            
      
      
            img{
              width:100%;
              height: 100%;
              object-fit: cover;
            }
      
          }
      

          .aboutText{
            display: flex;
            flex-direction: column;
            
            justify-content: center;
            
            height: 100%;
            width: 30%;
            margin: auto;
            padding: 3rem;

            text-align: inherit;
      
      
            h2{
              width: 100%;
              text-align: inherit;
              color: $bgColorGrey;
              font-size: 1.5rem;
            }
      
            p{
            text-align: inherit;

              color: $bgColorGrey;
              font-size: 1rem;
            }
          }





        }


      }
    
    }

  }


}

