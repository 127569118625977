@import '../../../utilities/variables.scss';



@media only screen and (max-width: $breakpoint-tablet-portrait) {

    
.homeAboutSection{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 75vh;

    background-color: $bgColorWhite;
    color: $textColor;

    flex-direction: column;


    .leftBlock{
        width:100%;
        height: auto;


        @include flexCenter();

        padding: 4rem;
        text-align: center;

        h1{
            font-size: 2rem;
        }
        p{
            font-size: 1rem;
        }
    }

    
    .rightBlock{
        width: 100%;
        height: 100%;

        padding-top: 2rem;
        padding-bottom: 2rem;
        
        div{
            height: 100%;
            width: 100%;

            div{
                height: 100%;
                width: 100%;
                .homeAboutImage{
                    height: 100%;
                    width: 100%;
        
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
        
                    }
                }
            }   
        }

    }

}

}

@media only screen and (min-width: $breakpoint-tablet-portrait + 1) {
        
    .homeAboutSection{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 75vh;

        background-color: $bgColorWhite;
        color: $textColor;
        
        .leftBlock{
            width: 50%;
            height: 100%;


            @include flexCenter();

            padding: 4rem;


            text-align: left;
            h1{
                font-size: 2rem;
            }
            p{
                font-size: 1rem;
            }
        }

        
        .rightBlock{
            width: 50%;
            height: 100%;

            padding-top: 2rem;
            padding-bottom: 2rem;
            
            div{
                height: 100%;
                width: 100%;

                div{
                    height: 100%;
                    width: 100%;
                    .homeAboutImage{
                        height: 100%;
                        width: 100%;
            
                        img{
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
            
                        }
                    }
                }   
            }

        }
    }
}
