
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.item {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 10px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
}

.outline {
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px solid white;
  border-radius: 50%;
}
