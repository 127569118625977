@import '../../../utilities/variables';








@media only screen and (max-width: $breakpoint-tablet-portrait) {

    .homeServicesSection{
        display: none;
    }

}

@media only screen and (min-width: $breakpoint-tablet-portrait +1) {



.homeServicesSection{

    


    .homeServicesWrapper{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: all;

        position: relative;

        width: 100%;
        height:100%;

        .homeSlideWrapper{
            width: 100%;
            height:100%;

            .servicesImage{
                height:100%;
                width: 100%;
                // background-position: center;
                // background: fixed;

                position: absolute;
                top: 0;
                left: 0;

                img{
                    height:100%;
                    width: 100%;

                    position: absolute;
                    top:0;
                    left:0;

                }
        
            }

        }



        img{
            width: 100%;
            height: 100%;
            filter: brightness(0.5);

            transition: cubic-bezier(0.075, 0.82, 0.165, 1);
            
        }

        .buttonswrapper{

            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;


            position: absolute;
            top:0;
            left:0;

            .serviceLink{

                @include flexCenter();

                width:32%;
                height: 100%;
                min-width: 200px;

                text-decoration: none;
                color: $bgColorWhite;

                &:hover{
                    background-color: rgba(0,0,0,0.2);


                }

                    .service{

                        z-index: 2;
                        @include flexCenter();
                        flex-direction: column;

                        // border-style: solid;
                        // border-color: crimson;
                        // border-width: 1px;

                        width:100%;
                        height: 100%;
                        min-width: 200px;

                        h2{
                            font-size: 2rem;
                        }  

                        p{
                            font-size: 1rem;;
                        }

                }

        }
        }
    }
    }
}