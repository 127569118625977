@import '../../../utilities/variables';


@media screen and (max-width: $breakpoint-phone) {
    .featuredProjectsContainer{
        box-sizing: border-box;
        width:100%;
        height: 100%;

        padding: 1.5rem;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;

        background-color: #ececec;


        .featuredProjectsTitle{
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            h1{
                font-size: 1.5rem;
                color: black;

            }

        }

        .cardWrapper{
            box-sizing: border-box;

            width: 100%;
            height: 50%;

            display: flex;
            align-items: center;
            justify-content: space-between;

            flex-wrap: wrap;


        }


    }


}



@media screen and (max-width: $breakpoint-tablet-portrait) and (min-width: $breakpoint-phone +1)   {
    .featuredProjectsContainer{
        box-sizing: border-box;
        width:100%;
        height: 100%;

        padding: 1.5rem;

        @include flexCenter();


        // display: flex;
        // align-items: flex-start;
        // justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;

        background-color: #ececec;


        .featuredProjectsTitle{
            width: 100%;

            @include flexCenter();

      
            h1{
                font-size: 1.5rem;
                color: black;

            }

        }

        .cardWrapper{
            box-sizing: border-box;

            width: 100%;
            height: 50%;

            @include flexCenter();

            flex-wrap: wrap;


        }


    }


}


@media screen and (max-width: $breakpoint-tablet-landscape) and (min-width: $breakpoint-tablet-portrait +1)   {
    .featuredProjectsContainer{
        box-sizing: border-box;
        width:100%;
        height: 100%;

        padding: 1.5rem;

        @include flexCenter();


        // display: flex;
        // align-items: flex-start;
        // justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;

        background-color: #ececec;


        .featuredProjectsTitle{
            width: 100%;

            @include flexCenter();

      
            h1{
                font-size: 1.5rem;
                color: black;

            }

        }

        .cardWrapper{
            box-sizing: border-box;

            width: 100%;
            height: 50%;

            @include flexCenter();

            flex-wrap: wrap;


        }


    }


}




  
@media screen and (min-width: $breakpoint-tablet-landscape + 1) {

    .featuredProjectsContainer{
        box-sizing: border-box;
        width:100%;
        height: 100%;

        padding: 3rem;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;

        background-color: #ececec;


        .featuredProjectsTitle{
            display: flex;
            align-items: center;
            justify-content: flex-start;

            width: 100%;
            h1{
                font-size: 1.5rem;
                color: black;

            }

        }

        .cardWrapper{
            box-sizing: border-box;

            width: 100%;
            height: 50%;

            display: flex;
            align-items: center;
            justify-content: space-between;

            flex-wrap: wrap;


        }


    }
  
}




