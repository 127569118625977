
@import "./breakpoints.scss";


.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  color: white;

  .page-wrap{
    height: auto;
    width: 100%;

  }


}


