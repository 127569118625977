@import'../../utilities/variables';




@media only screen and (max-width: $breakpoint-phone) {
    

.servicePageWrapper{

    width: 100%;
    height: auto;

    background-color: $bgColorWhite;

    padding: 1em;


    .serviceWrapper{

        margin: 0;
        padding:0;

        width: 100%;
        min-height: 100vh;


        color: $textColor;

        div{

            width: 100%;
            height: auto;
            

            .fadeInBox{
                width: 100%;
                height: auto;

                @include flexCenter();

                .displayImageRight{
                    @include flexCenter();
                    flex-direction: column-reverse;

                    width: 100%;
                    min-height: 50vh;

                    .startImg{

                        width:100%;
                        height:75%;
            
                        img{
                        // margin: 1rem;
                        object-fit: cover;
                        object-position: center;
                            width: 100%;
                            height:100%;
                        }
                    }
            
            
                    .headerTxt{
            
                        width:100%;
                        height: auto;
                    
            
                        color: $textColor;
            
                        padding: 1rem;
                        z-index: 1;
            
                        @include flexCenter();
            
                        p{
                            font-size: 1rem;
                        }
            
            
                    }


                }
                
                .displayImageLeft{
                   
                    @include flexCenter();
                    flex-direction: column-reverse;

                    width: 100%;
                    min-height: 50vh;

                    .img02{

                        width:100%;
                        height:75%;
       

                        img{
                            // margin: 1rem;
            
                            object-fit: cover;
                            object-position: center;
                    
                                width: 100%;
                                height:100%;
                            }
            
                    }
            
                    .serviceBodyTxt{
            
                        width:50%;
                        height: auto;
            
                        @include flexCenter();
                        flex-direction: column;
            
                        ul{
                            @include flexCenter();
                            flex-direction: column;
            
                            li{
                                p{
                                    padding:0;
                                    margin:0;
                                }
                              
                            }
            
                        }
            
            
                        p{
                            font-size: 1rem;
                        }
            
            
                    }

                }

            }


        }

    }

    .relatedProjects{
        min-height: 50vw;
        height: fit-content;
        width: 100%;

        background-color: $bgColorWhite;
        color: $textColor;

        .relatedProjectsHeader{ 
            font-weight: 600;
        }

        .relatedProjectContainer{
            @include flexCenter();
            flex-wrap: wrap;
            width: 100%;
            min-height: 50vh;
            height:fit-content;
        }

    }

}
}

@media only screen and (max-width: $breakpoint-tablet-portrait) and (min-width:$breakpoint-phone +1 ){
    

.servicePageWrapper{

    width: 100%;
    height: auto;

    background-color: $bgColorWhite;

    padding: 1em;


    .serviceWrapper{

        margin: 0;
        padding:0;

        width: 100%;
        min-height: 100vh;


        color: $textColor;

        div{

            width: 100%;
            height: auto;
            

            .fadeInBox{
                width: 100%;
                height: auto;

                @include flexCenter();

                .displayImageRight{
                    @include flexCenter();
                    flex-direction: column-reverse;

                    width: 100%;
                    min-height: 50vh;

                    .startImg{

                        width:100%;
                        height:75%;
            
                        img{
                        // margin: 1rem;
                        object-fit: cover;
                        object-position: center;
                            width: 100%;
                            height:100%;
                        }
                    }
            
            
                    .headerTxt{
            
                        width:100%;
                        height: auto;
                    
            
                        color: $textColor;
            
                        padding: 1rem;
                        z-index: 1;
            
                        @include flexCenter();
            
                        p{
                            font-size: 1rem;
                        }
            
            
                    }


                }
                
                .displayImageLeft{
                   
                    @include flexCenter();

                    width: 100%;
                    min-height: 50vh;
                    display: flex;
                    flex-direction: column-reverse;

                    .img02{

                        width:100%;
                        height:75%;
       

                        img{
                            // margin: 1rem;
            
                            object-fit: cover;
                            object-position: center;
                    
                                width: 100%;
                                height:100%;
                            }
            
                    }
            
                    .serviceBodyTxt{
            
                        width:50%;
                        height: auto;
            
                        @include flexCenter();
                        flex-direction: column;
            
                        ul{
                            @include flexCenter();
                            flex-direction: column;
            
                            li{
                                p{
                                    padding:0;
                                    margin:0;
                                }
                              
                            }
            
                        }
            
            
                        p{
                            font-size: 1rem;
                        }
            
            
                    }

                }

            }


        }

    }

    .relatedProjects{
        min-height: 50vw;
        height: fit-content;
        width: 100%;

        background-color: $bgColorWhite;
        color: $textColor;

        .relatedProjectsHeader{ 
            font-weight: 600;
        }

        .relatedProjectContainer{
            @include flexCenter();
            flex-wrap: wrap;
            width: 100%;
            min-height: 50vh;
            height:fit-content;
        }

    }

}
}

@media only screen and (max-width: $breakpoint-tablet-landscape) and (min-width:$breakpoint-tablet-portrait +1 ) {
    

.servicePageWrapper{

    width: 100%;
    height: auto;

    background-color: $bgColorWhite;

    padding: 1em;


    .serviceWrapper{

        margin: 0;
        padding:0;

        width: 100%;
        height: 100vh;


        color: $textColor;

        div{

            width: 100%;
            height: auto;
            

            .fadeInBox{
                width: 100%;
                height: auto;

                @include flexCenter();

                .displayImageRight{
                    @include flexCenter();
                    flex-direction: row-reverse;

                    width: 100%;
                    height: 50vh;

                    .startImg{

                        width:50%;
                        height:75%;
            
                        img{
                        // margin: 1rem;
                        object-fit: cover;
                        object-position: center;
                            width: 65%;
                            height:100%;
                        }
                    }
            
            
                    .headerTxt{
            
                        width:50%;
                        height: auto;
                    
            
                        color: $textColor;
            
                        padding: 1rem;
                        z-index: 1;
            
                        @include flexCenter();
            
                        p{
                            font-size: 1rem;
                        }
            
            
                    }


                }
                
                .displayImageLeft{
                   
                    @include flexCenter();

                    width: 100%;
                    height: 50vh;

                    .img02{

                        width:50%;
                        height:75%;
       

                        img{
                            // margin: 1rem;
            
                            object-fit: cover;
                            object-position: center;
                    
                                width: 65%;
                                height:100%;
                            }
            
                    }
            
                    .serviceBodyTxt{
            
                        width:50%;
                        height: auto;
            
                        @include flexCenter();
                        flex-direction: column;
            
                        ul{
                            @include flexCenter();
                            flex-direction: column;
            
                            li{
                                p{
                                    padding:0;
                                    margin:0;
                                }
                              
                            }
            
                        }
            
            
                        p{
                            font-size: 1rem;
                        }
            
            
                    }

                }

            }


        }

    }

    .relatedProjects{
        min-height: 50vw;
        height: fit-content;
        width: 100%;

        background-color: $bgColorWhite;
        color: $textColor;

        .relatedProjectsHeader{ 
            font-weight: 600;
        }

        .relatedProjectContainer{
            @include flexCenter();
            flex-wrap: wrap;
            width: 100%;
            min-height: 50vh;
            height:fit-content;
        }

    }

}
}

@media only screen and (min-width:$breakpoint-tablet-landscape +1 ) {
    

.servicePageWrapper{

    width: 100%;
    height: auto;

    background-color: $bgColorWhite;

    padding: 1em;


    .serviceWrapper{

        margin: 0;
        padding:0;

        width: 100%;
        height: 100vh;


        color: $textColor;

        div{

            width: 100%;
            height: auto;
            

            .fadeInBox{
                width: 100%;
                height: auto;

                @include flexCenter();

                .displayImageRight{
                    @include flexCenter();
                    flex-direction: row-reverse;

                    width: 100%;
                    height: 50vh;

                    .startImg{

                        width:50%;
                        height:75%;
            
                        img{
                        // margin: 1rem;
                        object-fit: cover;
                        object-position: center;
                            width: 65%;
                            height:100%;
                        }
                    }
            
            
                    .headerTxt{
            
                        width:50%;
                        height: auto;
                    
            
                        color: $textColor;
            
                        padding: 1rem;
                        z-index: 1;
            
                        @include flexCenter();
            
                        p{
                            font-size: 1rem;
                        }
            
            
                    }


                }
                
                .displayImageLeft{
                   
                    @include flexCenter();

                    width: 100%;
                    height: 50vh;

                    .img02{

                        width:50%;
                        height:75%;
       

                        img{
                            // margin: 1rem;
            
                            object-fit: cover;
                            object-position: center;
                    
                                width: 65%;
                                height:100%;
                            }
            
                    }
            
                    .serviceBodyTxt{
            
                        width:50%;
                        height: auto;
            
                        @include flexCenter();
                        flex-direction: column;
            
                        ul{
                            @include flexCenter();
                            flex-direction: column;
            
                            li{
                                p{
                                    padding:0;
                                    margin:0;
                                }
                              
                            }
            
                        }
            
            
                        p{
                            font-size: 1rem;
                        }
            
            
                    }

                }

            }


        }

    }

    .relatedProjects{
        min-height: 50vw;
        height: fit-content;
        width: 100%;

        background-color: $bgColorWhite;
        color: $textColor;

        .relatedProjectsHeader{ 
            font-weight: 600;
        }

        .relatedProjectContainer{
            @include flexCenter();
            flex-wrap: wrap;
            width: 100%;
            min-height: 50vh;
            height:fit-content;
        }

    }

}
}
