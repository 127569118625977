@import '../../utilities/variables';


.projectsWrapper{
    width: 100%;
    height: auto;

    // position: relative;

    background-color: $bgColorWhite;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media only screen and (max-width: $breakpoint-tablet-portrait) {
        flex-direction: column;
    }

    @media only screen and (min-width: $breakpoint-tablet-portrait + 1) {
        flex-direction: row;
    }



    .projectsContainer{

        @include flexCenter();
        flex-wrap: wrap;

        padding: 1em;

        min-height: 100vh;
        background-color: $bgColorWhite;

        @media only screen and (max-width: $breakpoint-tablet-portrait) {
            width: 100%;
        }
    
        @media only screen and (min-width: $breakpoint-tablet-portrait + 1) {
            width: 80%;   
        }

                
        .projectsCard{

            @include flexCenter();
            flex-wrap: wrap;

            width: 30%;
            min-width: 200px;
            height: 33vh;
            min-height: 400px;
            padding: 0;
            margin: 1em;

            
            button{
                border-style: hidden;
            }


                .projectContainer{
                    @include flexCenter();
                    flex-direction: column;
                    position: relative;

                    width: 100%;
                    height: 100%;

                    padding: 0;

                    background-color: white;

                
                
                    .projectImage{

                        width: 100%;
                        height: 100%;

                    
                        img{
                            width: 100%;
                            height:100%;
                            
                            object-fit: cover;

                        

                        }

                    }

                    .projectInfo{
                        width: 100%;
                        min-height:20%;

                        @include flexCenter();
                        flex-direction: column;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;

                        padding:1rem;
                        background-color: $bgColorWhiteDkr;


                        ul{
                            @include flexCenter();
                            flex-direction: column;
                            width: 100%;

                            li{
                                width: 100%;
                                
                                    h1{
                                        font-size: 1rem;
                                        font-weight: 600;
                                        color: $textColor;
                                        text-transform: uppercase;
                                        text-decoration: none;
                                    }

                                    .projectType{
                                        font-size: 0.8rem;
                                        font-weight: 200;
                                        color:$textColor;
                                    }

                                    .seeMoreBtn{
                                        width: 75%;
                                        height:50px;
                                        margin: 0.6rem;

                                        border-style: solid;
                                        border-color: $bgColorGrey;
                                        border-width: 2px;

                                        cursor: pointer;
                                        transition: 0.3s;

                                        &:hover{
                                            background-color: $bgColorGrey;
                                            color: $bgColorWhite;
                                        }

                                    }


                            }
                        }

                    }
                }
            }

    }
}